import React, { useState } from 'react';
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/react/24/outline';
import copy from 'copy-to-clipboard';

export default function Tokenomics() {
  const [_copy, setCopy] = useState(false);

  return (
    <div className="container mx-auto px-6" id="tokenomics">
      <div className=" mt-10 sm:mt-12 md:mt-16 lg:mt-[77px] ">
        <div className="flex justify-between gap-4 flex-col-reverse md:flex-row">
          <div className="tokenomics_bg md:w-1/2 mt-14 md:mt-0">
            <div
              className="font-SingleDay text-[22px] leading-7"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              Contract:
            </div>
            <div
              className="flex gap-2 "
              data-aos="fade-down"
              data-aos-delay="600"
              data-aos-duration="1600"
            >
              <div className="text-lg sm:text-xl md:text-2xl leading-8 mt-3 font-SingleDay  flex gap-2 justify-center ">
                <div className="w-[200px] sm:w-[250px] lg:w-full text-ellipsis overflow-hidden text-purple">
                  0x000000000000000000000000000000000000000000
                </div>
                <span
                  onClick={() => {
                    copy('0x000000000000000000000000000000000000000000');
                    setCopy(true);
                    setTimeout(() => {
                      setCopy(false);
                    }, 2000);
                  }}
                  className="flex items-center cursor-pointer"
                >
                  {!_copy ? (
                    <DocumentDuplicateIcon className="w-4 sm:w-6 font-SingleDay text-purple" />
                  ) : (
                    <CheckIcon className="w-4 sm:w-6 text-purple" />
                  )}
                </span>
              </div>
            </div>
            <div className="mt-9 sm:mt-11 md:mt-14 lg:mt-[68px] ">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
                <div>
                  <div
                    className="font-SingleDay text-2xl "
                    data-aos="fade-down"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                  >
                    Total Supply
                  </div>
                  <div
                    className="mt-2 font-Sniglet text-2xl text-purple"
                    data-aos="fade-right"
                    data-aos-delay="600"
                    data-aos-duration="1000"
                  >
                    420,960,000,000
                  </div>
                </div>
                <div>
                  <div
                    className="font-SingleDay text-2xl "
                    data-aos="fade-down"
                    data-aos-delay="600"
                    data-aos-duration="1000"
                  >
                    Tax
                  </div>
                  <div
                    className="mt-2 font-Sniglet text-2xl text-purple"
                    data-aos="fade-right"
                    data-aos-delay="800"
                    data-aos-duration="1000"
                  >
                    0%
                  </div>
                </div>
                <div>
                  <div
                    className="font-SingleDay text-2xl "
                    data-aos="fade-down"
                    data-aos-delay="600"
                    data-aos-duration="1000"
                  >
                    Liquidity
                  </div>
                  <div
                    className="mt-2 font-Sniglet text-2xl text-purple"
                    data-aos="fade-right"
                    data-aos-delay="800"
                    data-aos-duration="1000"
                  >
                    100% LP BURNED
                  </div>
                </div>
                <div>
                  <div
                    className="font-SingleDay text-2xl "
                    data-aos="fade-down"
                    data-aos-delay="800"
                    data-aos-duration="1000"
                  >
                    Ownership
                  </div>
                  <div
                    className="mt-2 font-Sniglet text-2xl text-purple"
                    data-aos="fade-right"
                    data-aos-delay="1000"
                    data-aos-duration="1000"
                  >
                    Renounced
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2">
            <div
              className=" text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-Sniglet md:text-right"
              data-aos="fade-right"
              data-aos-delay="1000"
              data-aos-duration="1000"
            >
              Tokenomics
            </div>
            <div className="mt-9 sm:mt-11 md:mt-14 lg:mt-[68px]">
              <p
                className="text-2xl font-SingleDay md:text-right md:w-4/5 ml-auto "
                data-aos="flip-left"
                data-aos-delay="800"
                data-aos-duration="1000"
              >
                We want Purple PEPE ALWAYS LIVE on the moon. We don't want Purple PEPE get back to earth
                after launch. That's why we are applying 0% tax so that the investors can hold for a long time.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="border border-white w-full border-opacity-10 mt-12 sm:mt-14 md:mt-16 lg:mt-[76px] "></div>
    </div>
  );
}
