import React from 'react';
import AboutUs from '../components/AboutUs';
import Tokenomics from '../components/Tokenomics';
import Join from '../components/Join';

export default function Home() {
  return (
    <div id="home">
      <AboutUs />
      <Tokenomics />
      <Join />
      {/* <Tokenomics /> */}
    </div>
  );
}
