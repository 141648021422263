import React from 'react';
import logo from '../assets/images/logo.svg';

export default function Footer() {
  return (
    <div className="container mx-auto px-4">
      <div
        className="flex justify-center lg:pt-[90px]"
        data-aos="zoom-in"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <div
          className="flex gap-2 lg:gap-7 font-SingleDay font-normal text-xl"
          data-aos="fade-down"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <a
            className="hover:text-purple"
            href="#home"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            Home
          </a>
          <a
            className="hover:text-purple"
            href="#aboutus"
            data-aos="fade-down"
            data-aos-delay="400"
            data-aos-duration="1200"
          >
            About
          </a>
          <a
            className="hover:text-purple"
            href="#tokenomics"
            data-aos="fade-down"
            data-aos-delay="600"
            data-aos-duration="1400"
          >
            Tokenomics
          </a>
          <a
            className="hover:text-purple"
            href="#roadmap"
            data-aos="fade-down"
            data-aos-delay="800"
            data-aos-duration="1600"
          >
            Roadmap
          </a>
          <a
            className="hover:text-purple"
            href="https://t.me/pupepe_erc"
            target="_blank"
            rel="noreferrer"
            data-aos="fade-down"
            data-aos-delay="1000"
            data-aos-duration="1800"
          >
            Community
          </a>
        </div>
      </div>
      <div
        className="md:flex md:justify-between  lg:pt-[54px] pb-8"
        data-aos="zoom-in"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <div
          className="font-SingleDay text-center md:text-left flex justify-center pt-10 md:pt-0  font-normal text-xl"
          // data-aos="fade-right"
          // data-aos-delay="300"
          // data-aos-duration="1000"
        >
          Made by PEPE W2 Team <br /> Copyright l Purple PEPE 2023. All rights reserved.
        </div>
        <div
          className="flex gap-[10px]  justify-center "
          // data-aos="fade-left"
          // data-aos-delay="300"
          // data-aos-duration="1000"
        >
          <img className=" w-auto" src={logo} alt="Pepe" />
          <div className="flex items-center font-Sniglet text-[32px] leading-10 ">Purple PEPE</div>
        </div>
      </div>
    </div>
  );
}
