import React from 'react';
import about_img from '../assets/images/about_img.png';

export default function AboutUs() {
  return (
    <div className="container mx-auto px-6" id="aboutus">
      <div className=" mt-10 sm:mt-12 md:mt-16 lg:mt-[77px] ">
        <div className="block sm:block md:flex justify-between gap-4">
          <div className="w-full sm:w-full md:w-2/4">
            <p
              className="font-Sniglet text-7xl md:text-8xl lg:text-9xl leading-[72px] "
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              About
            </p>
            <div className="mt-8">
              <p
                className="text-[24px] leading-10  font-SingleDay "
                data-aos="flip-left"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                We know Everyone is jumping pepe train recently but old pepe season is almost ended.Everyone is looking for new version.
                Purple PEPE would be very interesting version for investors!
                <br />
                Meet $PUPE TODAY and get amazing profit.
              </p>
            </div>
            <div className="mt-12 flex gap-5">
              <div
                className="font-SingleDay text-[24px] leading-10   text-purple  font-normal  "
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="500"
              >
                - Get $PUPE -
              </div>
            </div>
          </div>
          <div
            className="w-full sm:w-full md:w-2/4 lg:w-2/4 mt-5 sm:mt-5 md:mt-0"
            data-aos="fade-up"
            data-aos-delay="1000"
            data-aos-duration="5000"
          >
            <div>
              <img src={about_img} alt="master_punch" className="mx-auto" />
            </div>
          </div>
        </div>
      </div>
      <div className="border border-white w-full border-opacity-10 mt-12 sm:mt-14 md:mt-16 lg:mt-[76px] "></div>
    </div>
  );
}
