import React, { useState } from 'react';
import telegram from '../assets/images/telegram.svg';
import twitter from '../assets/images/twitter.svg';
import dextool from '../assets/images/dextool.svg';
import etherscan from '../assets/images/etherscan.svg';
import herosection_bg from '../assets/images/herosection_bg.png';
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/react/24/outline';
import copy from 'copy-to-clipboard';

export default function HeroSection() {
  const [_copy, setCopy] = useState(false);
  return (
    <div className=" container mx-auto  px-6">
      <div className="md:flex">
        <div className="w-full md:w-1/2 flex flex-col items-start gap-4 mt-16 animate__animated animate__backInLeft">
          <h1 className=" font-SingleDay text-2xl  ">Welcome to</h1>
          <h1
            className="font-Sniglet text-7xl md:text-8xl lg:text-9xl lg:leading-[155px] text-purple "
            data-aos="fade-down"
            data-aos-delay="400"
            data-aos-duration="800"
            data-aos-anchor-placement="bottom-bottom"
          >
            Purple PEPE
          </h1>
          <div className="mt-10">
            <p
              className="font-SingleDay text-2xl leading-7 "
              data-aos="fade-down"
              data-aos-delay="800"
              data-aos-duration="1200"
            >
              Missed PEPE1.0? Missed PEPE2.0?
              We are Purple PEPE. Join a Purple PEPE community of dank memers who know how to have fun and make profits!<br/>
              Don't miss a chance Today for getting profit from Purple PEPE!
            </p>
          </div>

          <div className="mt-7 flex gap-2 sm:gap-4 md:gap-5">
            <a
              href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x000000000000000000000000000000000000000000"
              target="_blank"
              rel="noreferrer"
            >
              <button className="font-Sniglet   text-base leading-5 font-normal py-3 px-8 rounded-[10px]  border border-white  hover:bg-purple hover:text-white  ">
                Buy Now
              </button>
            </a>
            <a
              href="https://etherscan.io/token/0x000000000000000000000000000000000000000000"
              target="_blank"
              rel="noreferrer"
            >
              <button className="font-Sniglet  text-white text-base leading-5 font-normal py-3 px-8 rounded-[10px] border border-white hover:bg-white hover:text-[#4D3F9E] ">
                Contract
              </button>
            </a>
          </div>
          <div className="mt-12 block md:hidden">
            <div className="md:w-1/2  ">
              <div
                className="font-SingleDay text-[22px] leading-7 "
                data-aos="fade-down"
                data-aos-delay="200"
                data-aos-duration="1200"
              >
                Links:
              </div>
              <div className="flex gap-2 mt-3">
                <a href="https://twitter.com/pepew2erc" target="_blank" rel="noreferrer">
                  <img
                    src={twitter}
                    alt="twitter"
                    data-aos="fade-down"
                    data-aos-delay="200"
                    data-aos-duration="1000"
                  />
                </a>
                <a href="https://t.me/pupepe_erc" target="_blank" rel="noreferrer">
                  <img
                    src={telegram}
                    alt="telegram"
                    ata-aos="fade-down"
                    data-aos-delay="400"
                    data-aos-duration="1200"
                  />
                </a>
                <a
                  href="https://www.dextools.io/app/en/ether/pair-explorer/0x000000000000000000000000000000000000000000"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={dextool}
                    alt="dextool"
                    data-aos="fade-down"
                    data-aos-delay="600"
                    data-aos-duration="1400"
                  />
                </a>
                <a
                  href="https://etherscan.io/token/0x000000000000000000000000000000000000000000"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={etherscan}
                    alt="etherscan"
                    data-aos="fade-down"
                    data-aos-delay="800"
                    data-aos-duration="1600"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 relative">
          <div className="block animate__animated   animate__fadeInRightBig ">
            <img src={herosection_bg} alt="" className="" />
          </div>
        </div>
      </div>
      <div className="md:flex justify-between mt-24 ">
        <div className="md:w-1/2 hidden md:block ">
          <div
            className="font-SingleDay text-[22px] leading-7 "
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="1200"
          >
            Links:
          </div>
          <div className="flex gap-2 mt-3">
            <a href="https://twitter.com/pupepe_erc" target="_blank" rel="noreferrer">
              <img
                src={twitter}
                alt="twitter"
                className="hover:scale-110 duration-300"
                data-aos="fade-down"
                data-aos-delay="200"
                data-aos-duration="1000"
              />
            </a>
            <a href="https://t.me/pupepe_erc" target="_blank" rel="noreferrer">
              <img
                src={telegram}
                alt="telegram"
                className="hover:scale-110 duration-300"
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-duration="1200"
              />
            </a>
            <a
              href="https://www.dextools.io/app/en/ether/pair-explorer/0x000000000000000000000000000000000000000000"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={dextool}
                alt="dextool"
                className="hover:scale-110 duration-300"
                data-aos="fade-down"
                data-aos-delay="600"
                data-aos-duration="1400"
              />
            </a>
            <a
              href="https://etherscan.io/token/0x000000000000000000000000000000000000000000"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={etherscan}
                alt="etherscan"
                className="hover:scale-110 duration-300"
                data-aos="fade-down"
                data-aos-delay="800"
                data-aos-duration="1600"
              />
            </a>
          </div>
        </div>
        <div className="md:w-1/2">
          <div className="text-center">
            <div
              className="font-SingleDay text-[22px] leading-7"
              data-aos="fade-down"
              data-aos-delay="800"
              data-aos-duration="1200"
            >
              Contract:
            </div>
            <div
              className="flex gap-2 justify-center"
              data-aos="fade-down"
              data-aos-delay="600"
              data-aos-duration="1600"
            >
              <div className="text-lg sm:text-xl md:text-2xl leading-8 mt-3 font-SingleDay  flex gap-2 justify-center ">
                <div className="w-[200px] sm:w-[250px] md:w-full text-ellipsis overflow-hidden text-purple">
                  0x000000000000000000000000000000000000000000
                </div>
                <span
                  onClick={() => {
                    copy('0x000000000000000000000000000000000000000000');
                    setCopy(true);
                    setTimeout(() => {
                      setCopy(false);
                    }, 2000);
                  }}
                  className="flex items-center cursor-pointer"
                >
                  {!_copy ? (
                    <DocumentDuplicateIcon className="w-4 sm:w-6 font-SingleDay text-purple" />
                  ) : (
                    <CheckIcon className="w-4 sm:w-6 text-purple" />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border border-white w-full border-opacity-10 mt-12 sm:mt-14 md:mt-16 lg:mt-[76px] "></div>
    </div>
  );
}
