import React from 'react';

const Join = () => {
  return (
    <div className="md:container md:mx-auto md:px-4" id="roadmap">
      <div
        className="bg_image max-[768px]:h-[707px] max-[768px]:w-full my-10 lg:my-[75px]"
        data-aos="zoom-in"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <div className="p-10">
          <h1
            className="font-Sniglet font-normal text-6xl lg:text-8xl"
            ata-aos="fade-right"
            data-aos-delay="600"
            data-aos-duration="1000"
          >
            Join <br /> Purple PEPE
          </h1>
          <p
            className="pt-3 lg:w-[38%] lg:pt-7 font-SingleDay font-normal text-2xl"
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="1000"
          >
            Join the Purple PEPE today and have a big fun and much profit!
          </p>
        </div>
      </div>
      <hr className="opacity-10" />
    </div>
  );
};

export default Join;
